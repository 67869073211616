import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'

class Author extends React.Component {
  constructor (props) {
    super(props)
    const index = 4
    const posts = props.data.allDatoCmsPost
      ? props.data.allDatoCmsPost.edges.slice(0, index)
      : []
    const allPostsShown = posts.length < 4
    this.state = { posts, index, allPostsShown }
    this.showMore = this.showMore.bind(this)
  }

  showMore () {
    const newIndex = this.state.index + 4
    const newPosts = this.props.data.allDatoCmsPost.edges.slice(
      this.state.index,
      newIndex
    )
    const currentPosts = this.state.posts
    const posts = currentPosts.concat(newPosts)
    const allPostsShown =
      posts.length >= this.props.data.allDatoCmsPost.edges.length
    this.setState({ posts, index: newIndex, allPostsShown })
  }

  render () {
    const page = this.props.data.datoCmsOurOceanPage
    const author = this.props.data.datoCmsAuthor
    const posts = () => {
      const posts = this.props.data.allDatoCmsPost
      if (posts) {
        return (
          <div className="columns is-multiline">
            {posts.edges.map(({ node: post }) => (
              <Link
                to={`/our-ocean/${post.slug}`}
                key={post.id}
                className="column is-6"
                style={{ marginBottom: '2rem' }}
              >
                <figure
                  className="image is-4by3"
                  style={{ marginBottom: '1rem' }}
                >
                  <Img fluid={post.featuredImage.fluid} alt={post.title} />
                </figure>
                <h3 className="subtitle is-size-6 has-text-weight-bold">
                  {post.title}
                </h3>
              </Link>
            ))}
          </div>
        )
      } else return <h3 className="subtitle">Coming Soon!</h3>
    }
    return (
      <Layout>
        <HelmetDatoCms seo={page.seoMetaTags}>
          <title>Posts by {author.name} | OrcaSpaces</title>
        </HelmetDatoCms>
        <section
          className="hero is-large is-page-hero"
          style={{
            backgroundImage: `url(${page.heroBackgroundImage.fixed.src})`
          }}
        >
          <div className="hero-body">
            <div className="container">
              <p className="has-text-white">Posts by</p>
              <h1 className="title has-text-white has-text-weight-normal">
                {author.name}
              </h1>
            </div>
          </div>
        </section>
        <section className="section has-text-centered">
          {posts()}
          <div
            className="container"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <button
              className="button is-black is-outlined is-centered"
              onClick={this.showMore}
              style={{ display: `${this.state.allPostsShown ? 'none' : ''}` }}
            >
              LOAD MORE ARTICLES
            </button>
          </div>
        </section>
      </Layout>
    )
  }
}

Author.propTypes = {
  data: PropTypes.object.isRequired
}

export default Author

export const query = graphql`
  query AuthorPostsQuery($authorSlug: String!) {
    datoCmsOurOceanPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroBackgroundImage {
        fixed(
          width: 1920
          imgixParams: {
            auto: "compress"
            fm: "pjpg"
            crop: "entropy"
            bri: -15
          }
        ) {
          src
        }
      }
    }
    datoCmsAuthor(slug: { eq: $authorSlug }) {
      id
      name
    }
    allDatoCmsPost(
      sort: { order: DESC, fields: publishedDate }
      filter: { author: { slug: { eq: $authorSlug } } }
    ) {
      totalCount
      edges {
        node {
          id
          title
          slug
          textNode {
            childMarkdownRemark {
              excerpt
            }
          }
          featuredImage {
            fluid(
              maxWidth: 800
              maxHeight: 500
              imgixParams: { fm: "pjpg", auto: "compress", crop: "entropy" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
